<script setup lang="ts">
defineProps<{ isDelete?: boolean, loading?: boolean }>()
const { isRevealed, cancel, confirm, reveal } = useConfirmDialog()
defineExpose({ reveal, isRevealed })
</script>

<template>
  <UModal
    v-model="isRevealed"
    @close="cancel"
    @keydown.enter.prevent="confirm"
  >
    <div class="p-4 text-base">
      <h3 v-if="$slots.title" class="mb-4 font-semibold">
        <slot name="title" />
      </h3>
      <slot />
      <div class="mt-4 flex justify-end">
        <UButton color="gray" variant="ghost" size="sm" @click="cancel">
          Cancel
        </UButton>
        <UButton class="ml-2" :color="isDelete ? 'red' : 'primary'" :loading size="sm" @click="confirm">
          {{ isDelete ? 'Delete' : 'Confirm' }}
        </UButton>
      </div>
    </div>
  </UModal>
</template>
